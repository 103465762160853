





















 import { Page, Sort, Filter, Edit, Toolbar  } from "@syncfusion/ej2-vue-grids";
import { Component, Vue } from 'vue-property-decorator'
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import {MyGridOption} from '@/Helper/MyDataAdapter';
Vue.use(GridPlugin);
@Component({
     provide:{
     grid: [Page, Sort, Filter, Edit, Toolbar ]
  }

})
export default class Order extends Vue {
    dataManager = MyGridOption.getDataManager('SessionContentList');
    filterOptions = {    type: 'Excel'};
     editSettings= { allowEditing: true, mode: 'Dialog',showDeleteConfirmDialog:true };
    toolbar= [ 'Edit'];
    actionFailure(args:any) {
      this.$store.commit("ChangeLoading", false);
      console.log(args);
      var t = args.error[0].error.response;
      console.log(t);
       this.$bus.$emit('testing_call',{data:t});
}
}
